<template>
  <v-row align="baseline">
    <v-col>
      <v-select
        v-model="value.type"
        :items="sources"
        :label="label"
        :hint="hint"
        :persistent-hint="hint ? true : false"
        @change="$emit('change')"
      ></v-select>
      <InputPriceMarket
        v-if="value.type === 'Market'"
        :num_years="num_years"
        :node-list="nodeList"
        :allowed-schedules="schedules"
        v-model="value.market"
        @change="$emit('change')"
      ></InputPriceMarket>
      <template v-if="value.type === 'Custom'">
        <v-row v-if="multiYear">
          <v-col>
            <v-text-field
              v-model.number="value.year_start"
              label="Start Year"
              type="number"
              @change="$emit('change')"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model.number="value.year_end"
              label="End Year"
              type="number"
              @change="$emit('change')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-select
          v-model="value.index"
          :items="schedules"
          label="Index Type"
          @change="$emit('change')"
        ></v-select>
        <InputPriceCustom
          :reserves="reserves"
          v-model="value.nodes"
          @change="$emit('change')"
        ></InputPriceCustom>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InputPrice",
  components: {
    InputPriceMarket: () => import("@/components/InputPriceMarket.vue"),
    InputPriceCustom: () => import("@/components/InputPriceCustom.vue"),
  },
  props: {
    reserves: Array,
    num_years: {
      type: Number,
      default: 2,
    },
    label: { type: String, required: false, default: "Price Source" },
    hint: { type: String, required: false, default: null },
    nodeList: Boolean,
    multiYear: Boolean,
    sequencing: {
      type: Object,
      required: false,
      default: () => ({
        active: false,
        allowedSources: ["Market", "Custom"],
        allowedSchedules: ["DA", "RT"],
      }),
    },

    value: {
      type: Object,
      required: false,
      default() {
        return {
          type: null,
          nodes: [],
          market: null,
        };
      },
    },
  },
  computed: {
    sources() {
      if (this.sequencing && this.sequencing.active) {
        return this.sequencing.allowedSources;
      }
      return ["PC", "Market", "Custom"];
    },
    schedules() {
      const schedules = [
        { text: "Day-Ahead", value: "DA" },
        { text: "Real Time", value: "RT" },
      ];
      if (this.sequencing && this.sequencing.active) {
        return schedules.filter((e) => this.sequencing.allowedSchedules.includes(e.value));
      }
      return schedules;
    },
  },
  data() {
    return {};
  },
};
</script>
